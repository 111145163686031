import { cacheNames, clientsClaim, setCacheNameDetails } from 'workbox-core';

const pagesToCache = [
    {
        key: 'home',
        remoteUrl: 'https://www.canolacouncil.org/home-calculator/',
        url: '/calculator/'
    },
    {
        key: 'resources',
        remoteUrl: 'https://www.canolacouncil.org/sclerotinia-resources-calculator/',
        url: '/calculator/sclerotinia-resources'
    },
    {
        key: 'ratingMethod',
        remoteUrl: 'https://www.canolacouncil.org/sclerotinia-rating-method-calculator/',
        url: '/calculator/sclerotinia-rating-method'
    },
    {
        key: 'gallery',
        remoteUrl: 'https://www.canolacouncil.org/sclerotinia-photo-gallery-calculator/',
        url: '/calculator/sclerotinia-photo-gallery'
    },
    {
        key: 'sclerotiaDepot',
        remoteUrl: 'https://www.canolacouncil.org/make-a-sclerotinia-depot-calculator/',
        url: '/calculator/make-a-sclerotinia-depot'
    }
];

/**
 * Fetches and caches a set of pages from WordPress.
 */
async function preloadRemotePages() {
    const cache = await caches.open(cacheNames.precache);

    await Promise.all(
        pagesToCache.map(async (page) => {
            const cachedHtml = await cache.match(page.key);

            // Skip fetching if offline and page is already cached
            if (cachedHtml && !navigator.onLine) {
                console.log(`Page ${page.key} already cached`);
                return;
            }

            try {
                const response = await fetch(page.remoteUrl);

                if (!response.ok) {
                    console.error(`Failed to fetch ${page.remoteUrl}`);
                    return;
                }

                const text = await response.text();
                const parser = new DOMParser();
                const doc = parser.parseFromString(text, 'text/html');

                // Inject script to prevent lazy loading
                const scriptContent = `
                    document.querySelectorAll('img[loading="lazy"]').forEach(img => {
                        img.loading = 'eager';
                    });
                `;
                const scriptTag = document.createElement("script");
                scriptTag.textContent = scriptContent;
                doc.head.appendChild(scriptTag);

                const newHtml = doc.documentElement.outerHTML;
                await cache.put(page.key, new Response(newHtml, { headers: { 'Content-Type': 'text/html' } }));

                console.log(`Cached HTML for ${page.key}`);

                // Cache additional assets
                await cacheLinkedAssets(doc, cache);

            } catch (error) {
                console.error(`Error fetching ${page.remoteUrl}:`, error);
            }
        })
    );
}

/**
 * Caches linked assets (CSS, JS, images) from the fetched page.
 */
async function cacheLinkedAssets(doc, cache) {
    const assetUrls = new Set();

    // Collect all asset URLs from the HTML document
    doc.querySelectorAll("link[rel='stylesheet']").forEach(link => assetUrls.add(link.href));
    doc.querySelectorAll("script[src]").forEach(script => assetUrls.add(script.src));
    doc.querySelectorAll("img[src]").forEach(img => assetUrls.add(img.src));

    await Promise.all([...assetUrls].map(async (url) => {
        if (!url) return;

        // Check if asset is already in cache
        const cachedResponse = await cache.match(url);
        if (cachedResponse) {
            return; // Skip fetching
        }

        try {
            const response = await fetch(url);
            if (response.ok) {
                await cache.put(url, response);
            }
        } catch (error) {
            console.error(`Failed to cache asset: ${url}`, error);
        }
    }));
}

/**
 * Loads the cached version of a page by its key.
 * @param {string} pageKey - The key of the page to load.
 */
async function loadCachedPage(pageKey) {
    console.log("loadCachedPage", pageKey);
    const cache = await caches.open(cacheNames.precache);
    const cachedResponse = await cache.match(pageKey);

    if (!cachedResponse) {
        console.log(`No cache found for page key: ${pageKey}. Preloading...`);
        await preloadRemotePages();
        return;
    }

    if (window.__contentReplaced) {
        return; // Content already replaced
    }

    const cachedHtml = await cachedResponse.text();
    window.__contentReplaced = true;

    document.open();
    document.write(cachedHtml);
    document.close();
}

/**
 * Helper function to determine if all pages are cached.
 * @returns {boolean}
 */
async function hasPageCache(pageKey = null) {
    const cache = await caches.open(cacheNames.precache);
  
    if (pageKey) {
      const cachedHtml = await cache.match(pageKey);
      return !!cachedHtml;
    }
  
    // Check all pages if no specific key is provided.
    for (const page of pagesToCache) {
      const cachedHtml = await cache.match(page.key);
      if (!cachedHtml) return false;
    }
  
    return true;
  }
  

export { preloadRemotePages, loadCachedPage, hasPageCache, pagesToCache };
