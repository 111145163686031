import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import Logo from 'images/canola-council-of-canada-header.png'
import CCCicon from 'images/ccc-icon.png';

const Sidebar = (props) => {
  let location = useLocation();

  return (
    <nav id="sidebar">
      <div className="wrap">
        { /* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href="http://canolacouncil.org/" target="_blank"><img src={Logo} id="logo" alt="Canola Council of Canada" /></a>
        <ul>

          <li>
            <span className="sub-nav-title">Canola Calculator Tools <div className="arrow-down"></div></span>
            <ul className="sub-nav">
              <li className={location.pathname.includes("seeding-rate") ? 'active' : ''}><Link className="seed-rate" to={process.env.PUBLIC_URL + '/seeding-rate'}>Seed Rate Calculator</Link></li>
              <li className={location.pathname === process.env.PUBLIC_URL + '/target-plant-density' ? 'active' : ''}><Link className="target-density" to={process.env.PUBLIC_URL + '/target-plant-density'}>Target Density Calculator</Link></li>
              <li className={location.pathname === process.env.PUBLIC_URL + '/canola-counts' ? 'active' : ''}><Link className="my-fields" to={process.env.PUBLIC_URL + '/canola-counts'}>Canola Counts</Link></li>
              <li className={location.pathname === process.env.PUBLIC_URL + '/harvest-loss' ? 'active' : ''}><Link className="harvest-loss" to={process.env.PUBLIC_URL + '/harvest-loss'}>Harvest Loss Calculator</Link></li>
              <li className={location.pathname === process.env.PUBLIC_URL + '/blackleg-loss' ? 'active' : ''}><Link className="blackleg-loss" to={process.env.PUBLIC_URL + '/blackleg-loss'}>Blackleg Yield Loss Calculator </Link></li>
              <li className={location.pathname === process.env.PUBLIC_URL + '/combine-optimization' ? 'active' : ''}><Link className="combine-optimization" to={process.env.PUBLIC_URL + '/combine-optimization'}>Combine Optimization Tool</Link></li>
              <li><a class="sclero-risk" href="https://www.canolacouncil.org/sclerotinia-calc/">Sclerotinia Risk Assessment</a></li>
              <li><a class="sclero-calculator" href="https://www.canolacouncil.org/sclerotinia-calc/economic-calculator">Sclerotinia Economic Calculator</a></li>
              <li><a class="sclero-login" href='https://www.canolacouncil.org/sclerotinia-calc/login'>Sclerotinia Login</a></li>
              <li className={location.pathname === process.env.PUBLIC_URL + '/sclerotinia-resources' ? 'active' : ''}><Link className="sclerotinia-resources" to={process.env.PUBLIC_URL + '/sclerotinia-resources'}>Sclerotinia Resources</Link></li>
              <li className={location.pathname === process.env.PUBLIC_URL + '/sclerotinia-rating-method' ? 'active' : ''}><Link className="sclerotinia-rating-method" to={process.env.PUBLIC_URL + '/sclerotinia-rating-method'}>Sclerotinia Rating Method</Link></li>
              <li className={location.pathname === process.env.PUBLIC_URL + '/sclerotinia-photo-gallery' ? 'active' : ''}><Link className="sclerotinia-photo-gallery" to={process.env.PUBLIC_URL + '/sclerotinia-photo-gallery'}>Sclerotinia Photo Gallery</Link></li>
              <li className={location.pathname === process.env.PUBLIC_URL + '/make-a-sclerotinia-depot' ? 'active' : ''}><Link className="make-a-sclerotia-depot" to={process.env.PUBLIC_URL + '/make-a-sclerotinia-depot'}>Make a Sclerotinia Depot</Link></li>
              {/* <li className={location.pathname === process.env.PUBLIC_URL + '/calculator' ? 'active' : ''}><Link className="calculator" to={'calculator'}>Canola Calculator (Home)</Link></li> */}
            </ul>
          </li>
          {/* <li className={location.pathname === '/canola-counts' ? 'active' : ''}><Link className="my-fields" to={process.env.PUBLIC_URL + '/canola-counts'}>Canola Counts</Link></li> */}
        </ul>
        <div id="back-to-ccc" className="back-to-ccc">
          <a href="https://www.canolacouncil.org/calculator">
            <img src={CCCicon} alt="Canola Council" />
            Return to Canola&nbsp;Calculator
          </a>
        </div>
      </div>


    </nav>
  );
}

export default Sidebar;
